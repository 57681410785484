import APIService from '@/services/APIService.js'

export default {
  getOrders () {
    return APIService.apiCall().get('/orders.json')
  },
  getRecentAuthorizedOrders () {
    return APIService.apiCall().get('/orders/recentauthorized.json')
  },
  postOrder (dataOrder,idOrder=null) {
    const postData = { ...dataOrder }
    if(idOrder) {
      return APIService.apiCall().post('/orders/edit/'+idOrder+'.json',JSON.stringify(postData))
    }
    return APIService.apiCall().post('/orders/add.json',JSON.stringify(postData))
  },
  getOrder (id) {
    return APIService.apiCall().get('/orders/view/'+id+'.json')
  },
  deleteOrder(id) {
    return APIService.apiCall().delete('/orders/delete/'+id+'.json')
  },
  getNextOrderId(){
    return APIService.apiCall().delete('/orders/next.json')
  },
  track(code,cpf) {
    return APIService.apiCall().get('/orders/track/'+code+'/'+cpf+'.json')
  },
  approve(decision,id){
    return APIService.apiCall().get('/orders/approve/'+decision+'/'+id+'.json')
  }
}
