<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <div id="canvas" ref="htmlToPdf" class="inner-outline">
      <v-row class="ma-5">
        <v-col md="7" cols="12">
          <div>
            <p>
              <span class="font-weight-black"  style="font-size:30px;">NÚCLEO DIGITAL</span>
            </p>
            <p>
              <span class="font-weight-black">Rua 68, 683, Centro </span>
              <br>
              <span class="font-weight-black">CEP 74055-100, Goiânia Goiás </span>
              <br>
              <span class="font-weight-black">Whatsapp: (62) 9-8201-5540 </span>
              <br>
              <span class="font-weight-black">Telefone (62) 3092-4473 </span>
              <br>
              <span class="font-weight-black">Email: centrotecnicogyn@hotmail.com </span>
              <br>
              <span class="font-weight-black">CNPJ: 10.354.327/0001-15 </span>
            </p>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <img src="@/assets/logo.jpg"
            width="200px"
            height="150px"
            class="ma-5"
          >
        </v-col>
      </v-row>

      <v-divider class="ma-5" inset></v-divider>

      <v-row class="ma-5">
        <v-col md="3" cols="12">
          <p style="font-size:15px;"><span class="font-weight-bold">Data:</span> {{modified}}</p>
          <p style="font-size:15px;"><span class="font-weight-bold">Cliente:</span> {{order.client.name}}</p>
          <p style="font-size:15px;"><span class="font-weight-bold">Endereço:</span> {{order.client.address}}</p>
        </v-col>
        <v-col md="3" cols="12">
          <p style="font-size:15px;"><span class="font-weight-bold">CEP:</span> {{order.client.cep}}</p>
          <p style="font-size:15px;"><span class="font-weight-bold">Telefone:</span> {{order.client.tel}}</p>
          <p style="font-size:15px;"><span class="font-weight-bold">Email:</span> {{order.client.email}}</p>
        </v-col>
        <v-col md="6" cols="12">
          <v-row align="center" justify="center">
            <p class="mr-15" style="font-size:30px;">Ordem Nº: {{order.id}}</p>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="ma-5" inset></v-divider>

      <v-row class="ma-5">
        <v-col cols="12">
          <div>
            <p style="font-size:20px;">Falha Apresentada: </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-textarea
          name="input-7-1"
          filled
          auto-grow
          :value="order.service_request.failure"
        ></v-textarea>
      </v-row>

      <v-row class="ma-5">
        <v-col cols="12">
          <div>
            <p style="font-size:20px;">Descrição do Serviço: </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-textarea
          name="input-7-1"
          filled
          auto-grow
          :value="order.description"
        ></v-textarea>
      </v-row>

      <v-divider class="ma-5" inset></v-divider>

      <v-row class="ma-5">
        <v-col md="12" cols="12">
          <p style="font-size:20px;">Produtos: </p>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size:15px;" class="text-left font-weight-bold">
                    Produto
                  </th>
                  <th style="font-size:15px;" class="text-left font-weight-bold">
                    Quantidade
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in order.products"
                  :key="product.id"
                >
                  <td>{{ product.name }}</td>
                  <td> {{ order.order_products.find(x => x.product_id === product.id).qty }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-divider class="ma-5" inset></v-divider>

      <v-row class="ma-5">
        <v-col md="12" cols="12">
          <p style="font-size:20px;">Totais: </p>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><span class="font-weight-black">Serviços:</span></td>
                  <td>R$ {{ order.service_price }}</td>
                </tr>
                <tr v-if="order.discount > 0">
                  <td><span class="font-weight-black">Descontos:</span></td>
                  <td>R$ - {{ order.discount }}</td>
                </tr>
                <tr>
                  <td><span class="font-weight-black">Total:</span></td>
                  <td><span class="font-weight-black">R$ {{ totalPrice }}</span></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row class="ma-5">
        <v-col md="6" cols="12" align="center" justify="center">
          <p class="font-weight-black" style="font-size:15px;">Autorizado Por</p>
          <p class="mt-15" style="font-size:15px;">________________________________________________</p>
        </v-col>
        <v-col md="6" cols="12" align="center" justify="center">
          <p class="font-weight-black" style="font-size:15px;">Núcleo Digital</p>
          <p class="mt-15" style="font-size:15px;">________________________________________________</p>
        </v-col>
      </v-row>
      <v-row class="ma-5">
      </v-row>
    </div>
    <div>
      <v-row class="ma-5">
        <v-col md="12" cols="12" align="center" justify="center">
          <v-btn
            color="info"
            min-width="100"
            @click="generatePdf"
          >
            Gerar PDF
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import OrdersService from '@/services/OrdersService'
  import dayjs from 'dayjs'
  import jsPDF  from "jspdf"
  import domtoimage from 'dom-to-image'

  export default {
    name: 'GenerateOrder',

    components: { 

    },

    data: () => ({
      order: {},
      servicePrice: 0,
      discount: 0,
      modified: '',
      productList: [],
      totalPrice: 0
    }),

    mounted () {
      this.getOrder(this.$route.params.id)
    },

    methods: {

      getOrder(id) {
        OrdersService.getOrder(id)
        .then(response => {
          console.log(response)
          this.order          = response.data.order
          this.modified       = dayjs(response.data.order.modified).format('DD/MM/YY')
          this.totalPrice     = (parseFloat(response.data.order.service_price) - parseFloat(response.data.order.discount)).toFixed(2)
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar ordem.', '',{position:'topRight'})
        })
      },
      generatePdf(){
        this.$toast.info('Gerando PDF...', '',{position:'topRight'})
        var htmlDiv = document.getElementById( 'canvas' );
        domtoimage.toPng( htmlDiv , { quality: 1.0 }).then( ( dataUrl ) => {
          var htmlImage = new Image();
          htmlImage.src = dataUrl;
          var pdf = new jsPDF('l', 'pt', [720, 720] );          
          pdf.addImage( htmlImage, 3, 3, 720, 720 );
          pdf.save( 'ordem_de_servico_'+this.$route.params.id+'.pdf');
        }).catch( ( error ) => {
          this.$toast.error('Erro ao gerar PDF.', '',{position:'topRight'})
        });

      }
    },
  }
</script>

<style scoped>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
  .inner-outline {
    outline: 0.5px solid black;
    outline-offset: -10px;
  }
</style>